<template>
  <div>
    <banner :path="bannerPath"></banner>
    <div class="service-box">
      <items-list :items="service"></items-list>
    </div>
    <side-code class="static-code">
      <p>想了解更多产品？微信扫码咨询</p>
    </side-code>
  </div>
</template>

<script>
import { banner, itemsList, sideCode } from '@/components'
import service from '@/assets/js/service.js';
import bannerPath from '@/assets/images/service/banner.jpg'
export default {
  components: {
    banner, itemsList, sideCode
  },
  data () {
    return {
      bannerPath,
      service
    }
  },
  mounted () {


  },
  methods: {
    
  }
}
</script>

<style lang="scss">
  @import '@/assets/scss/_variables.scss';
  @import '@/assets/scss/_extend.scss';
  .service-box{
    padding: 60px 0 30px;
  }
  .static-code{
    position: relative;
    top: 0; left: 0;
    margin: 0;
    transform: translate(0,0);
    width: auto;
    text-align: center;
    padding-bottom: 60px;
    &:hover{
      margin: 0;
    }
  }

  @media screen and (max-width:1200px) {
    .service-box{
      padding: px2rem(30px) 0 px2rem(15px);
    }
    .static-code{
      padding-bottom: px2rem(30px);
    }
  }
</style>
